import axios from 'axios';

export const createContact = async (contactName, contactTitle, contactPhone, contactEmail) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/contacts',
      data: {
        contactName,
        contactTitle,
        contactPhone,
        contactEmail,
      },
    });
    if(res.data.status === 'success'){
      newContactCard(res.data.data.document.id, res.data.data.document.contactName, res.data.data.document.contactTitle, res.data.data.document.contactPhone, res.data.data.document.contactEmail, true);
      $('#addContactModal').modal('toggle'); 
      $('#addContactForm')[0].reset()
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteContact = async (contactID) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/contacts/${contactID}`,
    });
    $(`#contactCard_${contactID}`).remove();
  } catch (err) {
    console.log(err);
  }
};

export const editContact = async (
  contactName,
  contactTitle,
  contactPhone,
  contactEmail,
  contactID
) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/contacts/${contactID}`,
      data: {
        contactName,
        contactTitle,
        contactPhone,
        contactEmail,
      },
    });
    if(res.data.status === 'success'){
      $(`#contactNameDisplay_${res.data.data.document.id}`).text(res.data.data.document.contactName);
      $(`#contactTitleDisplay_${res.data.data.document.id}`).text(res.data.data.document.contactTitle);
      $(`#contactEmailDisplay_${res.data.data.document.id}`).text(res.data.data.document.contactEmail);
      $(`#contactPhoneDisplay_${res.data.data.document.id}`).text(res.data.data.document.contactPhone);
      $('#editContactModal').modal('toggle'); 
      $('#editContactForm')[0].reset()
    }
  } catch (err) {
    console.log(err);
  }
};

function newContactCard(contactID, contactName, contactTitle, contactPhone, contactEmail, newContact) {
  var contactCard = document.createElement('div');
  contactCard.id = `contactCard_${contactID}`;
  contactCard.className = "col";
  contactCard.innerHTML = `<div id="checkboxes">
      <input class="contactcard" type="checkbox" id="contact${contactID}"/>
        <label class="card managementselect contactselect" for="contact${contactID}">
          <div class="card-body">
            <h5 class="card-title" id="contactNameDisplay_${contactID}">${contactName}</h5>
            <h6 class="card-subtitle mb-2" id="contactTitleDisplay_${contactID}">${contactTitle}</h6>
            <h6 class="card-subtitle mb-2" id="contactPhoneDisplay_${contactID}">${contactPhone}</h6>
            <h6 class="card-subtitle mb-2" id="contactEmailDisplay_${contactID}">${contactEmail}</h6>
            <a class="deletebutton contactDeleteButton" data-toggle="modal" data-target="#deleteContactConfirmationModal" data-contactID="${contactID}">
              <img class="feather" src="/images/delete2.svg" /></a>
            <a class="editbutton contactEditButton" data-toggle="modal" data-target="#editContactModal" data-contactID="${contactID}">
              <img class="feather" src="/images/edit.svg" />
            </a>
          </div>
        </label>
      </div>`;
  
  if(newContact == true){
    $("#contactContainer").append(contactCard);
    $(`#contact${contactID}`).attr("checked", true);
  } else {
    $("#contactContainer").append(contactCard);
  }
}

export function createContactCards() {
  $('.contactCardConstructor').each(function() {
    let currentCard = $(this);
    let contactID = $(this).attr('data-contactID');
    let contactName = $(this).attr('data-contactName');
    let contactTitle = $(this).attr('data-contactTitle');
    let contactPhone = $(this).attr('data-contactPhone');
    let contactEmail = $(this).attr('data-contactEmail');
    newContactCard(contactID, contactName, contactTitle, contactPhone, contactEmail, false);
    $('.attachedContactCardConstructor').each(function() {
      const allCards = currentCard.attr('data-contactID');
      const attachedCard = $(this).attr('data-contactID');
      if(allCards == attachedCard){
        $(`#contact${currentCard.attr('data-contactID')}`).prop("checked", true);
      }
    });
  });
  $("#contactCardConstruction").remove();
  $("#attachedContactCardConstruction").remove();
}

$('#contactContainer').on('click', '.contactEditButton', function(event) {
  let contactID = $(this).attr('data-contactID');
  $("#editContactName").val($(`#contactNameDisplay_${contactID}`).text());
  $("#editContactTitle").val($(`#contactTitleDisplay_${contactID}`).text());
  $("#editContactPhone").val($(`#contactPhoneDisplay_${contactID}`).text());
  $("#editContactEmail").val($(`#contactEmailDisplay_${contactID}`).text());
  $("#currentContactID").val(contactID);
});

$('#contactContainer').on('click', '.contactDeleteButton', function(e) {
  let contactID = $(this).attr('data-contactID');
  $("#currentContactIDDelete").val(contactID);
})


$("#addContactForm").on('submit', function(e) {
  e.preventDefault();
  createContact($("#addContactName").val(), $("#addContactTitle").val(), $("#addContactPhone").val(), $("#addContactEmail").val());
});

$("#editContactForm").on('submit', function(e) {
  e.preventDefault();
  editContact($("#editContactName").val(), $("#editContactTitle").val(), $("#editContactPhone").val(), $("#editContactEmail").val(), $("#currentContactID").val());
});

$("#deleteContactConfirm").on('click', function(e) {
  deleteContact($("#currentContactIDDelete").val());
});

export function contactCheck() {
  let ids = [];
  $('.contactcard').each(function () {
    if ($(this).prop('checked') == true) {
      ids.push($(this).attr('id').substring(7));
    }
  });
  return ids;
}

