/* eslint-disable */
var QRCode = require("qrcode-svg");
$("#viewTagQR").on("click", function(e) {

  const tagid = document.getElementById('tagid').value;
  var tag = new QRCode({
    content: `https://app.taggini.com/scan/${tagid}`,
    padding: 1.0,
    width: 400,
    height: 400,
    color: "#000000",
    background: "#ffffff",
    ecl: "H",
    join: true,
  });
  var svgElement = tag.svg();
  $("#tagModalWrapper").html(svgElement);
  $("#tagModalWrapper > svg").attr("id", "tagSVG");
  $("#tagSVG").attr("height", "450");
  var tlGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  var tlPath1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  var tlPath2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  var path1 = "M167.8,249.4c-5.5,0-10.5-3.2-12.7-8.3c-2.2-5.2-1.1-11.3,2.9-15.4l3.5-3.5c-3.8-3.2-7.3-6.9-10.8-10.7 l-0.2-0.2c-0.8-0.8-1.4-1.5-2-2.1c-0.7-0.7-2.1-2.2-5.8-6.4c-0.1-0.1-0.2-0.2-0.3-0.3l-0.4-0.4c-3.8-4.4-7-8.1-7-15.7 c0-12.1,9.9-22,22-22c4.9,0,9.5,1.7,13.2,4.6c2.3-1.8,4.9-3.3,7.6-4.6c1.3-6.9,7.4-12.1,14.6-12.1c7.2,0,13.4,5.2,14.6,12.1 c2.4,1.1,4.8,2.4,6.9,4c0.2-0.3,0.3-0.4,0.4-0.6c-0.4-0.4-0.8-0.9-1.2-1.3c-2.4-3-3-7.1-1.3-10.6c1.6-3.5,5.2-5.7,9.1-5.7h25.1 c5.4,0,10.6,2.3,14.2,6.4c3.5,4,5.1,9.3,4.4,14.7c-0.4,2.9-1.4,5.6-3,7.8c0.4,0.5,0.9,1.2,1.3,1.8c1.3,1.7,2.2,3.9,2.2,6.2v13 c0,7.6-6.1,13.8-13.6,13.9c-0.5,0-8.6,0.4-17.3,4.4c-3.5,1.6-6.6,3.6-9.5,5.6l2,2l0.3,0.3c3.8,4,4.8,9.8,2.7,14.8 c-2.1,5.1-7.2,8.6-12.7,8.6h-49.4v-0.3H167.8z";
  var path2 = "M251.3,191H219l-4.5-7.1c-4.5-7-11.9-11.4-20-12.1c1.6-0.8,2.7-2.4,2.7-4.3c0-2.7-2.2-4.8-4.8-4.8c-2.7,0-4.8,2.2-4.8,4.8 c0,1.9,1.1,3.6,2.7,4.3c-8.2,0.6-15.6,5.1-20,12.1l-1.3,2.1c-0.5-6.2-5.7-11.2-11.9-11.2c-6.2,0-11.9,5.4-11.9,11.9 c0,4,1.3,5.3,4.9,9.6c7.7,8.3,5.5,6,7.7,8.3c6.4,6.7,11.9,12.7,19.3,16.4l-11.9,11.9c-1.1,1.1-1.4,2.8-0.8,4.2 c0.6,1.4,2,2.3,3.5,2.3h49.3c1.5,0,3-0.9,3.5-2.3c0.6-1.4,0.3-3.1-0.8-4.2l-11-10.6c0.6-0.3,1.3-0.6,1.9-1c1.4-0.9,3-2,4.8-3.2 c3.8-2.7,8.5-6,14.5-8.8c11.1-5.1,21.3-5.3,21.4-5.3c2.1,0,3.8-1.7,3.8-3.9v-5.4C255.1,192.6,253.5,191,251.3,191L251.3,191z M176.7,188c3.4-5.4,9.3-8.6,15.8-8.6c6.5,0,12.3,3.2,15.8,8.6l1.9,3h-35.4L176.7,188L176.7,188z M157.1,182.5 c2.3,0,4.2,1.9,4.2,4.2c0,2.3-1.9,4.2-4.2,4.2s-4.2-1.9-4.2-4.2C152.9,184.3,154.6,182.5,157.1,182.5L157.1,182.5z M201,225 l6.7,6.7H177l6.7-6.7H201L201,225z M226.8,202.2c-6.6,3.1-11.9,6.8-15.7,9.5c-1.6,1.2-3.1,2.2-4.3,2.9c-2.7,1.6-7.2,2.7-11.4,2.7 c-15.4,0-22.1-7.2-32.2-18l-0.8-0.8h74.7C233.8,199.5,230.3,200.5,226.8,202.2L226.8,202.2z M255.1,187.3 c-2.9-4.6-7.9-7.6-13.7-7.6h-11.8c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1h16.2c4.4,0,8.3-3.1,8.9-7.5c0.7-5.3-3.4-9.8-8.6-9.8H221 c4,4.8,9.9,7.6,16.1,7.6h9.1c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1H230c-4.6,0-8.6,3.4-9,8c-0.4,4.8,3.1,8.7,7.7,9.2L255.1,187.3 L255.1,187.3L255.1,187.3z";
  tlPath1.setAttributeNS(null, "d", path1);
  tlPath1.setAttributeNS(null, "style", "fill:#FFFFFF;");
  tlPath2.setAttributeNS(null, "d", path2);
  var rect = document.createElementNS('https://www.w3c.org/2000/svg', 'rect');
  rect.setAttributeNS(null, 'x', '0');
  rect.setAttributeNS(null, 'y', '400');
  rect.setAttributeNS(null, 'height', '50');
  rect.setAttributeNS(null, 'width', '400');
  rect.setAttributeNS(null, 'style', 'fill:#FFFFFF');
  tlGroup.appendChild(tlPath1);
  tlGroup.appendChild(tlPath2);
  $("#tagSVG").append(tlGroup);
  $("#tagSVG").append(rect);
  var txt = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  txt.setAttributeNS(null, 'x', "50%");
  txt.setAttributeNS(null, 'y', "400");
  txt.setAttributeNS(null, 'dominant-baseline', 'hanging');
  txt.setAttributeNS(null, 'text-anchor', 'middle');
  txt.setAttributeNS(null,'font-size','48');
  txt.innerHTML = document.getElementById('tagIdentifier').innerHTML;
  $("#tagSVG").append(txt);  
})

function drawBorder() {
  var newLine = document.createElementNS('http://www.w3.org/2000/svg','line');
  newLine.setAttribute('id','leftBorder');
  newLine.setAttribute('x1','0');
  newLine.setAttribute('y1','0');
  newLine.setAttribute('x2','0');
  newLine.setAttribute('y2','450');
  newLine.setAttribute("stroke", "black");
  newLine.setAttribute("stroke-width", "6");
  var newLine2 = document.createElementNS('http://www.w3.org/2000/svg','line');
  newLine2.setAttribute('id','leftBorder');
  newLine2.setAttribute('x1','0');
  newLine2.setAttribute('y1','450');
  newLine2.setAttribute('x2','400');
  newLine2.setAttribute('y2','450');
  newLine2.setAttribute("stroke", "black");
  newLine2.setAttribute("stroke-width", "6");
  var newLine3 = document.createElementNS('http://www.w3.org/2000/svg','line');
  newLine3.setAttribute('id','leftBorder');
  newLine3.setAttribute('x1','400');
  newLine3.setAttribute('y1','450');
  newLine3.setAttribute('x2','400');
  newLine3.setAttribute('y2','0');
  newLine3.setAttribute("stroke", "black");
  newLine3.setAttribute("stroke-width", "6");
  var newLine4 = document.createElementNS('http://www.w3.org/2000/svg','line');
  newLine4.setAttribute('id','leftBorder');
  newLine4.setAttribute('x1','400');
  newLine4.setAttribute('y1','0');
  newLine4.setAttribute('x2','0');
  newLine4.setAttribute('y2','0');
  newLine4.setAttribute("stroke", "black");
  newLine4.setAttribute("stroke-width", "6");
  $("#tagSVG").append(newLine);
  $("#tagSVG").append(newLine2);
  $("#tagSVG").append(newLine3);
  $("#tagSVG").append(newLine4);
}