/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const createTag = async (tagTitle, tagDesc, tagIdentifier, tagNote, contactIDs, linkIDs, fileIDs, securityEnabled, tagPassword) => {
  try {
    const responseData = {
      tagTitle: tagTitle,
      tagDesc,
      tagIdentifier: tagIdentifier,
      tagNote: tagNote,
      tagType: 'tag',
      contacts: contactIDs,
      links: linkIDs,
      files: fileIDs,
      securityEnabled: securityEnabled,
    };
    if (tagPassword) {
      responseData.tagPassword = tagPassword;
    }
    const res = await axios({
      method: 'POST',
      url: '/api/v1/tags/',
      data: responseData
    });
    if (res.data.status === 'success') {
      window.setTimeout(() => {
        location.assign(res.data.url);
      });
    }
  } catch (err) {
    showAlert('error', "Please correct the following errors and try again.");
  }
};

export const editTag = async (tagTitle, tagDesc, tagIdentifier, tagNote, contactIDs, linkIDs, fileIDs, securityEnabled, tagPassword, emails) => {
  try {
    const responseData = {
      tagTitle: tagTitle,
      tagDesc,
      tagIdentifier: tagIdentifier,
      tagNote: tagNote,
      contacts: contactIDs,
      links: linkIDs,
      files: fileIDs,
      securityEnabled: securityEnabled,   
    };
    if (securityEnabled) {
      responseData.authorizedUsers = emails;
    }
    if (tagPassword) {
      responseData.tagPassword = tagPassword;
    }
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/tags/' + window.location.pathname.split('/').pop(),
      data: responseData
    });
    if (res.data.status === 'success') {
      window.setTimeout(() => {
        location.assign(res.data.url);
      });
    }
  } catch (err) {
    showAlert('error', "Please correct the following errors and try again.");
  }
};

export const deleteTag = async (tagID, tagType) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/${tagType}s/${tagID}`,
    });
    $(`[data-tagidrow='tag_${tagID}']`).remove();
    showAlert("success", "Tag deleted successfully!");
  } catch (err) {
    showAlert('error', "There was an error, please try again.");
  }
};

export const deleteTagForm = async (tagID, tagType) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/${tagType}s/${tagID}`,
    });
    window.setTimeout(() => {
      location.assign('/tags');
    });  
  } catch (err) {
    showAlert('error', "There was an error, please try again.");
  }
};

//Employee Tag

export const createEmployee = async (
  tagTitle,
  tagDesc,
  tagIdentifier,
  tagNote,
  employeeName,
  employeeTitle,
  employeeID,
  employeePhone,
  employeeEmail,
  emergencyContactName,
  emergencyContactRel,
  emergencyContactPhone,
  emergencyContactEmail,
  emergencyContactHRCase,
  emergencyContactProvider,
  contactIDs,
  linkIDs,
  fileIDs,
  employeePhotoKey,
  employeePhotoUrl,
  securityEnabled, 
  tagPassword,
  emails,
) => {
  try {
    const responseData = {
      tagTitle,
      tagDesc,
      tagIdentifier,
      tagNote,
      employeeName,
      employeeTitle,
      employeeID,
      employeePhone,
      employeeEmail,
      emergencyContactName,
      emergencyContactRel,
      emergencyContactPhone,
      emergencyContactEmail,
      emergencyContactHRCase,
      emergencyContactProvider,
      contacts: contactIDs,
      links: linkIDs,
      files: fileIDs,
      employeePhotoKey,
      employeePhotoUrl,
      tagType: 'employee',
      securityEnabled,
    };
    if (securityEnabled) {
      responseData.authorizedUsers = emails;
    }
    if (tagPassword) {
      responseData.tagPassword = tagPassword;
    }
    const res = await axios({
      method: 'POST',
      url: '/api/v1/employees',
      data: responseData
    });
    if (res.data.status === 'success') {
      window.setTimeout(() => {
        location.assign(res.data.url);
      });
    }
  } catch (err) {
    showAlert('error', "Please correct the following errors and try again.");
  }
};

export const editEmployee = async (
  tagTitle,
  tagDesc,
  tagIdentifier,
  tagNote,
  employeeName,
  employeeTitle,
  employeeID,
  employeePhone,
  employeeEmail,
  emergencyContactName,
  emergencyContactRel,
  emergencyContactPhone,
  emergencyContactEmail,
  emergencyContactHRCase,
  emergencyContactProvider,
  contactIDs,
  linkIDs,
  fileIDs,
  employeePhotoKey,
  employeePhotoUrl,
  securityEnabled, 
  tagPassword,
  emails,
) => {
  try {
    const responseData = {
      tagTitle,
      tagDesc,
      tagIdentifier,
      tagNote,
      employeeName,
      employeeTitle,
      employeeID,
      employeePhone,
      employeeEmail,
      emergencyContactName,
      emergencyContactRel,
      emergencyContactPhone,
      emergencyContactEmail,
      emergencyContactHRCase,
      emergencyContactProvider,
      contacts: contactIDs,
      links: linkIDs,
      files: fileIDs,
      employeePhotoKey,
      employeePhotoUrl,
      securityEnabled,
    };
    if (securityEnabled) {
      responseData.authorizedUsers = emails;
    }
    if (tagPassword) {
      responseData.tagPassword = tagPassword;
    }
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/employees/' + window.location.pathname.split('/').pop(),
      data: responseData
    });
    if (res.data.status === 'success') {
      window.setTimeout(() => {
        location.assign(res.data.url);
      });
    }
  } catch (err) {
    showAlert('error', "Please correct the following errors and try again.");
  }
};

export const deleteEmployee = async () => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: '/api/v1/employees/' + window.location.pathname.split('/').pop(),
    });
    window.setTimeout(() => {
      location.assign('/tags');
    });
    showAlert("success", "Record deleted successfully!");
  } catch (err) {
    showAlert('error', "There was an error, please try again.");
  }
};

// Video Tag Operations

export const createVideo = async (
  tagTitle,
  tagDesc,
  tagIdentifier,
  videoLink,
  tagNote,
) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/videos',
      data: {
        tagTitle,
        tagDesc,
        tagIdentifier,
        videoLink,
        tagNote,
        tagType: 'video',
      },
    });
    if (res.data.status === 'success') {
      window.setTimeout(() => {
        location.assign(res.data.url);
      });
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const editVideo = async (
  tagTitle,
  tagDesc,
  tagIdentifier,
  videoLink,
  tagNote,
) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/videos/' + window.location.pathname.split('/').pop(),
      data: {
        tagTitle,
        tagDesc,
        tagIdentifier,
        videoLink,
        tagNote,
      },
    });
    if (res.data.status === 'success') {
      window.setTimeout(() => {
        location.assign(res.data.url);
      });
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const deleteVideo = async () => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: '/api/v1/videos/' + window.location.pathname.split('/').pop(),
    });
    window.setTimeout(() => {
      location.assign('/tags');
    });
  } catch (err) {
    console.log(err.response.data.message);
  }
};

export const securityConfirmation = async (tagID, tagType, tagPassword) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/secure/verify/${tagID}`,
      params: {
        tagType,
        tagPassword,
      },
    });
    if (res.data.status === 'success') {
      window.setTimeout(() => {
        location.assign(res.data.url);
      });
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      showAlert('error', 'Incorrect Password. Please check your credentials.');
    } else if (err.response && err.response.data.message) {
      showAlert('error', err.response.data.message);
    } else {
      console.log(err);
      showAlert('error', 'An error occurred while processing the request.');
    }
  }
};