/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const uploadFile = async (fileData) => {
	var formData = new FormData();
  fileData.forEach(file => {
    formData.append("file", file);
  });

  document.getElementById('progressDiv').style.display = '';

  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/files',
      data: formData,
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        document.getElementById('progressBar').setAttribute("aria-valuenow", percentCompleted);
        document.getElementById('progressBar').style["width"] = percentCompleted + "%";
        document.getElementById('progressBar').innerHTML = percentCompleted + "% uploaded";
        if (percentCompleted == 100) {
          document.getElementById('progressBar').innerHTML = "Processing...";
        }
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });

    if (res.data.status === 'success') {
      document.getElementById('progressBar').classList.add('bg-success');
      document.getElementById('progressBar').innerHTML = "Upload Successful";
      setTimeout(function(){ 
        document.getElementById('progressDiv').style.display = 'none';
      }, 3000);
      res.data.data.files.forEach(function (item){
        const filetype = item.fileType.split('/');
        if (filetype[0] === 'image') {
          newImageCard(item.displayName, item.fileType, item.id, item.fileViewURL, true);
        } else {
          newFileCard(item.displayName, item.fileTypeFormatted, item.id, true);
        }
      });
      clearFileInput();
      showAlert('success', "File successfully uploaded!");
    }
  } catch (err) {
    showAlert('error', "An error occured, please try again.");
  }
};

export const uploadEmployeePhoto = async (imageData) => {

  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/employees/photos',
      data: imageData,
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  
    if (res.data.status === 'success') {
      let img = res.data.data.url;  
      $("#employeePhoto").css({"background-image": "url(" + img + ")"});
      $(`#employeePhoto`).attr("data-url", res.data.data.url);
      $(`#employeePhoto`).attr("data-fileKey", res.data.data.fileKey);  
      $('#employeePhotoUploadForm')[0].reset()
      showAlert('success', "File successfully uploaded!");
    }
    } catch (err) {
      showAlert('error', "An error occured, please try again.");
    }

};


export const editFile = async (fileID, displayName) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/files/${fileID}`,
      data: {
        displayName
      },
    });
    if(res.data.status === 'success'){
      $(`#fileTitleDisplay_${res.data.data.document.id}`).text(res.data.data.document.displayName);
      $('#editFileModal').modal('toggle'); 
      $('#editFileForm')[0].reset()
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteFile = async (fileID) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/files/${fileID}`,
    });
    if (type === 'card') {
      $(`#fileCard_${fileID}`).remove();
    } else if (type === 'list') {
      $(`#fileList_${fileID}`).remove();
    }
  } catch (err) {
    console.log(err);
  }
};


function clearFileInput(){
  $('#fileUploadForm')[0].reset()
};

export function newFileCard(fileName, fileType, fileID, newUpload) {
  var fileCard = document.createElement('div');
  fileCard.id = `fileCard_${fileID}`;
  fileCard.className = "col";
  fileCard.innerHTML = `<div id='checkboxes'>
    <input type='checkbox' class="filecard" id='file${fileID}'/>
    <label class='card managementselect' for='file${fileID}'><div class='card-body'>
      <h5 class='card-title' id="fileTitleDisplay_${fileID}">${fileName}</h5>
      <h6 class='card-subtitle mb-2'>${fileType}</h6>
          <a class="deletebutton fileDeleteButton" data-toggle="modal" data-target="#deleteFileConfirmationModal" data-fileID="${fileID}">
            <img src="/images/delete2.svg" class="feather">
          </a>
          <a class="editbutton fileEditButton" data-toggle="modal" data-target="#editFileModal" data-fileID="${fileID}">
            <img src="/images/edit.svg" class="feather">
          </a>
      </div>
    </label>
  </div>`;  
  $("#fileContainer").append(fileCard);
  if(newUpload == true) {
    $(`#file${fileID}`).attr("checked", true);
  }
}

export function newImageCard(fileName, fileType, fileID, imageLink, newUpload) {
  var imageCard = document.createElement('div');
  imageCard.id = `fileCard_${fileID}`;
  imageCard.className = "col";
  imageCard.innerHTML = `<div id="checkboxes">
  <input type="checkbox" class="filecard" id="file${fileID}"/>
    <label class="card managementselectimages" for="file${fileID}">
      <img src="${imageLink}" class="card-img-top imageselect">
        <div class="card-body">
          <h5 class="card-title" id="fileTitleDisplay_${fileID}">${fileName}</h5>
          <a class="deletebutton fileDeleteButton" data-toggle="modal" data-target="#deleteFileConfirmationModal" data-fileID="${fileID}">
            <img src="/images/delete2.svg" class="feather">
          </a>
          <a class="editbutton fileEditButton" data-toggle="modal" data-target="#editFileModal" data-fileID="${fileID}">
            <img src="/images/edit.svg" class="feather">
          </a>
        </div>
    </label>
</div>`;
$("#fileContainer").append(imageCard);
if(newUpload == true){
  $(`#file${fileID}`).attr("checked", true);
}
}

const capitalize = (str) => {
  if(typeof str === 'string') {
      return str.replace(/^\w/, c => c.toUpperCase());
  } else {
      return '';
  }
};

export function createFileCards() {
  $('.fileCardConstructor').each(function(){
    let currentCard = $(this);
    let fileName = $(this).attr('data-fileName');
    let fileID = $(this).attr('data-fileID');
    let imageLink = $(this).attr('data-viewURL');
    let fileType = $(this).attr('data-fileType').split('/').shift();
    let fileTypeFormatted = $(this).attr('data-fileTypeFormatted');
    if(fileType === 'image'){
      newImageCard(fileName, fileTypeFormatted, fileID, imageLink, false);
    } else {
      newFileCard(fileName, fileTypeFormatted, fileID, false);
    }
    $('.attachedFileCardConstructor').each(function() {
      const allCards = currentCard.attr('data-fileID');
      const attachedCard = $(this).attr('data-fileID');
      if(allCards == attachedCard){
        $(`#file${currentCard.attr('data-fileID')}`).prop("checked", true);
      }
    });
  });
  $("#fileCardConstruction").remove();
  $("#attachedFileCardConstruction").remove();
}

$('#fileContainer').on('click', '.fileEditButton', function(event) {
  let fileID = $(this).attr('data-fileID');
  $("#editFileDisplayTitle").val($(`#fileTitleDisplay_${fileID}`).text());
  $("#currentFileID").val(fileID);
});

$('#fileContainer').on('click', '.fileDeleteButton', function(e) {
  let fileID = $(this).attr('data-fileID');
  $("#currentFileIDDelete").val(fileID);
});

$("#editFileForm").on('submit', function(e) {
  e.preventDefault();
  editFile($('#currentFileID').val(), $('#editFileDisplayTitle').val());
});

$("#deleteFileConfirm").on('click', function(e) {
  deleteFile($("#currentFileIDDelete").val());
});

export function fileCheck() {
  let ids = [];
  $('.filecard').each(function () {
    if ($(this).prop('checked') == true) {
      ids.push($(this).attr('id').substring(4));
    }
  });
  return ids;
};