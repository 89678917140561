import axios from 'axios';

export const createLink = async (linkTitle, linkURL) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/links',
      data: {
        linkTitle,
        linkURL: httpCheck(linkURL),
      },
    });
    if(res.data.status === 'success') {
      newLinkCard(res.data.data.document.id, res.data.data.document.linkTitle, res.data.data.document.linkURL, true);
      $('#addLinkModal').modal('toggle'); 
      $('#addLinkForm')[0].reset()
    }
  } catch (err) {
    console.log(err);
  }
};
const deleteLink = async (linkID) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/links/${linkID}`,
    });
    $(`#linkCard_${linkID}`).remove();
  } catch (err) {
    console.log(err);
  }
};

const editLink = async (linkID, linkTitle, linkURL) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/links/${linkID}`,
      data: {
        linkTitle,
        linkURL: httpCheck(linkURL),
      },
    });
    if(res.data.status === 'success') {
      $(`#linkTitleDisplay_${res.data.data.document.id}`).text(res.data.data.document.linkTitle);
      $(`#linkURLDisplay_${res.data.data.document.id}`).text(res.data.data.document.linkURL);
      $('#editLinkModal').modal('toggle'); 
      $('#editLinkForm')[0].reset()
    }
  } catch (err) {
    console.log(err);
  }
};

function newLinkCard(linkID, linkTitle, linkURL, newLink) {
  var linkCard = document.createElement('div');
  linkCard.id = `linkCard_${linkID}`;
  linkCard.className = "col";
  linkCard.innerHTML = `<div id="checkboxes">
  <input class="linkcard" type="checkbox" id="link${linkID}"/>
    <label class="card managementselect" for="link${linkID}">
      <div class="card-body">
        <h5 id="linkTitleDisplay_${linkID}" class="card-title">${linkTitle}</h5>
        <h6 id="linkURLDisplay_${linkID}" class="card-subtitle mb-2">${linkURL}</h6>
        <a href="#" class="deletebutton linkDeleteButton" data-toggle="modal" data-target="#deleteLinkConfirmationModal" data-linkID="${linkID}">
          <img src="/images/delete2.svg" class="feather">
        </a>
        <a href="#" class="editbutton linkEditButton" data-toggle="modal" data-target="#editLinkModal" data-linkID="${linkID}">
          <img src="/images/edit.svg" class="feather">
        </a>
      </div>
    </label>
  </div>`;
  $("#linkContainer").append(linkCard);
  if(newLink == true){
    $(`#link${linkID}`).attr("checked", true);
  } 
}

export function createLinkCards() {
  $('.linkCardConstructor').each(function() {
    let currentCard = $(this);
    let linkID = $(this).attr('data-linkID');
    let linkTitle = $(this).attr('data-linkTitle');
    let linkURL = $(this).attr('data-linkURL');
    newLinkCard(linkID, linkTitle, linkURL, false);
    $('.attachedLinkCardConstructor').each(function() {
      const allCards = currentCard.attr('data-linkID');
      const attachedCard = $(this).attr('data-linkID');
      if(allCards == attachedCard){
        $(`#link${currentCard.attr('data-linkID')}`).prop("checked", true);
      }
    });
  });
  $("#linkCardConstruction").remove();
  $("#attachedLinkCardConstruction").remove();
}

$('#linkContainer').on('click', '.linkEditButton', function(event) {
  let linkID = $(this).attr('data-linkID');
  $("#editLinkTitle").val($(`#linkTitleDisplay_${linkID}`).text());
  $("#editLinkURL").val($(`#linkURLDisplay_${linkID}`).text());
  $("#currentLinkID").val(linkID);
});

$('#linkContainer').on('click', '.linkDeleteButton', function(e) {
  let linkID = $(this).attr('data-linkID');
  $("#currentLinkIDDelete").val(linkID);
})


$("#addLinkForm").on('submit', function(e) {
  e.preventDefault();
  createLink($('#addLinkTitle').val(), $('#addLinkURL').val());
});

$("#editLinkForm").on('submit', function(e) {
  e.preventDefault();
  editLink($("#currentLinkID").val(), $("#editLinkTitle").val(), $("#editLinkURL").val())
});

$("#deleteLinkConfirm").on('click', function(e) {
  deleteLink($("#currentLinkIDDelete").val());
});

export function linkCheck() {
  let ids = [];
  $('.linkcard').each(function () {
    if ($(this).prop('checked') == true) {
      ids.push($(this).attr('id').substring(4));
    }
  });
  return ids;
};

const httpCheck = url => !/^https?:\/\//i.test(url) ? `https://${url}` : url;

