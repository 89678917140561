/* eslint-disable */
import 'regenerator-runtime/runtime';
import { showAlert } from './alerts';
import { login, logout, register, forgotPassword, resetPassword } from './auth';
import { createTag, editTag, deleteTag, createEmployee, editEmployee, deleteTagForm, createVideo, editVideo, deleteVideo, securityConfirmation } from './tag';
import { editGroup, createGroup, deleteGroup } from './group';
import { createContactCards, contactCheck} from './contact';
import { createLinkCards, linkCheck } from './link';
import { uploadFile, createFileCards, fileCheck, uploadEmployeePhoto, deleteFile } from './file';
import { getCheckoutSession, getPortalSession } from './order';
import * as qr from './qr';
var SvgSaver = require('svgsaver'); 
var svgsaver = new SvgSaver();
const emailInput = $('.accessEmails');
const emailRow = $('.emailRow');

let userPills = [];

//Auth
const deleteButtonForm = document.querySelector('.deleteButtonForm');
//Video
const newVideoForm = document.querySelector('.newVideoForm');
const editVideoForm = document.querySelector('.editVideoForm');
//Group
const newGroupForm = document.querySelector('.newGroupForm');
const editGroupForm = document.querySelector('.editGroupForm');
//Files
const fileUploadForm = document.querySelector('.fileUploadForm');
const fileInput = document.getElementById('fileUpload');



//Auth functions

$('.loginForm').on('submit', function(e) {
  e.preventDefault();
  const userEmail = document.getElementById('useremail').value;
  const userPassword = document.getElementById('password').value;
  login(userEmail, userPassword);
});


$('.registerForm').on('submit', function(e) {
  e.preventDefault();
  const userName = document.getElementById('userName').value;
  const userEmail = document.getElementById('userEmail').value;
  const userPassword = document.getElementById('userPassword').value;
  const userPasswordConfirm = document.getElementById('userPasswordConfirm').value;
  register(userName, userEmail, userPassword, userPasswordConfirm);
  $("#registerBox").addClass("hideRegItem");
  $("#tosBox").removeClass("hideRegItem");
});

$(".termsForm").on("submit", function(e) {
  e.preventDefault();
  //Add logic and API endpoint to accept terms here.
  $("#tosBox").addClass("hideRegItem");
  $("#planBox").removeClass("hideRegItem");
});

$(".planForm").on("submit", function(e) {
  e.preventDefault();
  //Add logic and API endpoint to accept terms here.
  const productType = $("#productType").val();
  const productIndex = $("#productIndex").val();
  getCheckoutSession(productType, productIndex);
});

$("#basicPlanSelect").on("click", function(e) {
  $("#planSubmitButton").removeClass("hideRegItem");
  $("#regLinkButton").addClass("hideRegItem");
  $("#productType").val("subscription");
  $("#productIndex").val("1");
  $("#planSubmitButton").html("Select Plan");
});

$("#standardPlanSelect").on("click", function(e) {
  $("#planSubmitButton").removeClass("hideRegItem");
  $("#regLinkButton").addClass("hideRegItem");
  $("#productType").val("subscription");
  $("#productIndex").val("2");
  $("#planSubmitButton").html("Select Plan");
});

$("#proPlanSelect").on("click", function(e) {
  $("#planSubmitButton").removeClass("hideRegItem");
  $("#regLinkButton").addClass("hideRegItem");
  $("#productType").val("subscription");
  $("#productIndex").val("3");
  $("#planSubmitButton").html("Select Plan");
});

$("#enterprisePlanSelect").on("click", function(e) {
  $("#planSubmitButton").addClass("hideRegItem");
  $("#regLinkButton").removeClass("hideRegItem");
  $("#regLinkButton").html("Go to Dashboard");
  $("#regLinkButton").attr("href", "/dashboard");
});

$("#singleTagSelect").on("click", function(e) {
  $("#planSubmitButton").removeClass("hideRegItem");
  $("#regLinkButton").addClass("hideRegItem");
  $("#productType").val("payment");
  $("#productIndex").val("0");
  $("#planSubmitButton").html("Select Product");
});

$("#viewOnly").on("click", function(e) {
  $("#planSubmitButton").addClass("hideRegItem");
  $("#regLinkButton").removeClass("hideRegItem");
  $("#regLinkButton").html("Go to Dashboard");
  $("#regLinkButton").attr("href", "/dashboard");
});


$('.forgotForm').on('submit', function(e) {
  e.preventDefault();
  const userEmail = document.getElementById('userEmail').value;
  if (userEmail === '') {
    showAlert('warn', 'Please enter an email address.');
  } else {
    forgotPassword(userEmail);
  }
});

$('.resetForm').on('submit', function(e) {
  e.preventDefault();
  const userPassword = document.getElementById('userPassword').value;
  const userPasswordConfirm = document.getElementById('userPasswordConfirm').value;
  if(userPassword === userPasswordConfirm) {
    resetPassword(userPassword, userPasswordConfirm);
  } else {
    showAlert('warn', "The passwords do not match, please try again.");
  }
});

$(document).on('click', '#manageSubscriptions', function(e) {
  getPortalSession();
});


if (editGroupForm) {
  editGroupForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const groupName = document.getElementById('groupName').value;
    const tagIDs = [];
    $('input:checkbox[name=selectedTag]:checked').each(function () {
      tagIDs.push($(this).val());
    });
    editGroup(groupName, tagIDs);
  });
  deleteButtonForm.addEventListener('click', (e) => {
    deleteGroup();
  });
}

if (newGroupForm) {
  newGroupForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const groupName = document.getElementById('groupName').value;
    const tagIDs = [];
    $('input:checkbox[name=selectedTag]:checked').each(function () {
      tagIDs.push($(this).val());
    });
    createGroup(groupName, tagIDs);
  });
}

  $('.logoutButton').on('click', function () {
    logout();
  });

  if ($(".tagForm").length > 0){
    createFileCards();
    createContactCards();
    createLinkCards();
  }


  $('.newTagForm').on('submit', function(e) {
    e.preventDefault();
    const tagTitle = document.getElementById('tagTitle').value;
    const tagDesc = document.getElementById('tagDesc').value;
    const tagIdentifier = document.getElementById('tagIdentifier').value;
    const tagNote = document.getElementById('tagNote').value;
    let contactIDs = contactCheck();
    let linkIDs = linkCheck();
    let fileIDs = fileCheck();
    let securityEnabled = $(".securitySwitch").prop("checked");
    let tagPassword = document.getElementById('tagPassword').value;
    createTag(tagTitle, tagDesc, tagIdentifier, tagNote, contactIDs, linkIDs, fileIDs, securityEnabled, tagPassword);
});

  $('.editTagForm').on('submit', function(e) {
    e.preventDefault();
    const tagTitle = document.getElementById('tagTitle').value;
    const tagDesc = document.getElementById('tagDesc').value;
    const tagIdentifier = document.getElementById('tagIdentifier').value;
    const tagNote = document.getElementById('tagNote').value;
    let contactIDs = contactCheck();
    let linkIDs = linkCheck();
    let fileIDs = fileCheck();
    let securityEnabled = $(".securitySwitch").prop("checked");
    let tagPassword = document.getElementById('tagPassword').value;
    editTag(tagTitle, tagDesc, tagIdentifier, tagNote, contactIDs, linkIDs, fileIDs, securityEnabled, tagPassword, emails);
  });
  

  $('.securityform').on('submit', function(e) {
    e.preventDefault();
    const tagID = document.getElementById('tagID').value;
    const tagType = document.getElementById('tagType').value;
    const tagPassword = document.getElementById('password').value;
    securityConfirmation(tagID, tagType, tagPassword);
  });



$('.newEmployeeForm').on('submit', function(e) {
  e.preventDefault();
  const tagTitle = document.getElementById('tagTitle').value;
  const tagDesc = document.getElementById('tagDesc').value;
  const tagIdentifier = document.getElementById('tagIdentifier').value;
  const tagNote = document.getElementById('tagNote').value;
  const employeeName = document.getElementById('employeeName').value;
  const employeeTitle = document.getElementById('employeeTitle').value;
  const employeeID = document.getElementById('employeeID').value;
  const employeePhone = document.getElementById('employeePhone').value;
  const employeeEmail = document.getElementById('employeeEmail').value;
  const emergencyContactRel = document.getElementById('emergencyContactRel').value;
  const emergencyContactName = document.getElementById('emergencyContactName').value;
  const emergencyContactPhone = document.getElementById('emergencyContactPhone').value;
  const emergencyContactEmail = document.getElementById('emergencyContactEmail').value;
  const emergencyContactHRCase = document.getElementById('emergencyContactHRCase').value;
  const emergencyContactProvider = document.getElementById('emergencyContactProvider').value;
  const fileURL = $('#employeePhoto').attr('data-url');
  const fileKey = $('#employeePhoto').attr('data-fileKey');
  let contactIDs = contactCheck();
  let linkIDs = linkCheck();
  let fileIDs = fileCheck();
  let securityEnabled = $(".securitySwitch").prop("checked");
  let tagPassword = document.getElementById('tagPassword').value;
  createEmployee(
    tagTitle,
    tagDesc,
    tagIdentifier,
    tagNote,
    employeeName,
    employeeTitle,
    employeeID,
    employeePhone,
    employeeEmail,
    emergencyContactName,
    emergencyContactRel,
    emergencyContactPhone,
    emergencyContactEmail,
    emergencyContactHRCase,
    emergencyContactProvider,
    contactIDs,
    linkIDs,
    fileIDs,
    fileKey,
    fileURL,
    securityEnabled, 
    tagPassword,
    emails,
  );
});

$('.editEmployeeForm').on('submit', function(e) {
  e.preventDefault();
  const tagTitle = $('#tagTitle').val();
  const tagDesc = document.getElementById('tagDesc').value;
  const tagIdentifier = document.getElementById('tagIdentifier').value;
  const tagNote = document.getElementById('tagNote').value;
  const employeeName = document.getElementById('employeeName').value;
  const employeeTitle = document.getElementById('employeeTitle').value;
  const employeeID = document.getElementById('employeeID').value;
  const employeePhone = document.getElementById('employeePhone').value;
  const employeeEmail = document.getElementById('employeeEmail').value;
  const emergencyContactName = document.getElementById('emergencyContactName').value;
  const emergencyContactRel = document.getElementById('emergencyContactRel').value;
  const emergencyContactPhone = document.getElementById('emergencyContactPhone').value;
  const emergencyContactEmail = document.getElementById('emergencyContactEmail').value;
  const emergencyContactHRCase = document.getElementById('emergencyContactHRCase').value;
  const emergencyContactProvider = document.getElementById('emergencyContactProvider').value;
  const fileURL = $('#employeePhoto').attr('data-url');
  const fileKey = $('#employeePhoto').attr('data-fileKey');
  let contactIDs = contactCheck();
  let linkIDs = linkCheck();
  let fileIDs = fileCheck();
  let securityEnabled = $(".securitySwitch").prop("checked");
  let tagPassword = document.getElementById('tagPassword').value;
  editEmployee(
    tagTitle,
    tagDesc,
    tagIdentifier,
    tagNote,
    employeeName,
    employeeTitle,
    employeeID,
    employeePhone,
    employeeEmail,
    emergencyContactName,
    emergencyContactRel,
    emergencyContactPhone,
    emergencyContactEmail,
    emergencyContactHRCase,
    emergencyContactProvider,
    contactIDs,
    linkIDs,
    fileIDs,
    fileKey,
    fileURL,
    securityEnabled, 
    tagPassword,
    emails,
  );
});

// Video Tag Operations
if (newVideoForm) {
  newVideoForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const tagTitle = document.getElementById('tagTitle').value;
    const tagDesc = document.getElementById('tagDesc').value;
    const tagIdentifier = document.getElementById('tagIdentifier').value;
    const videoID = YouTubeGetID(document.getElementById('videoLink').value);
    const tagNote = document.getElementById('tagNote').value;
    const videoLink = "https://www.youtube.com/embed/" + videoID;
    createVideo(
      tagTitle,
      tagDesc,
      tagIdentifier,
      videoLink,
      tagNote,
    );
  });
}




if (fileUploadForm) {
  $(document).on('change', '#fileUpload', function() {
    const selectedFiles = [...fileInput.files];
    uploadFile(selectedFiles);
  })
}


$(document).on('change', '#employeePhotoUpload', function() {
  let form = new FormData();
  form.append("photo", document.getElementById('employeePhotoUpload').files[0]);
  uploadEmployeePhoto(form);
});


if (editVideoForm) {
  editVideoForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const tagTitle = document.getElementById('tagTitle').value;
    const tagDesc = document.getElementById('tagDesc').value;
    const tagIdentifier = document.getElementById('tagIdentifier').value;
    const videoID = YouTubeGetID(document.getElementById('videoLink').value);
    const tagNote = document.getElementById('tagNote').value;
    const videoLink = "https://www.youtube.com/embed/" + videoID;
    editVideo(
      tagTitle,
      tagDesc,
      tagIdentifier,
      videoLink,
      tagNote,
    );
  });
  deleteButtonForm.addEventListener('click', (e) => {
    deleteTag();
  });
}

function YouTubeGetID(url){
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
}

$('.phoneField').on('input', function () {
  $(this).val(
    $(this)
      .val()
      .replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, '$1-$2-$3')
  );
});

$('.deleteButtonFile').on('click', function () {
  const fileid = $(this).data('fileid');
  $('#deleteFileConfirmationModal').modal('toggle');
  $('#deleteFileConfirm').on('click', function () {
    deleteFile(fileid);
  });
});

$('.deleteButtonTag').on('click', function () {
  const type = $(this).data('type');
  const tagid = $(this).data('tagid');
  $('#deleteConfirmationModal').modal('toggle');
  $('#deleteConfirm').on('click', function () {
    deleteTag(tagid, type);
  });
});

$('.deleteButtonForm').on('click', function () {
  const type = $(this).data('type');
  const tagid = $(this).data('tagid');
  $('#deleteConfirmationModal').modal('toggle');
  $('#deleteConfirm').on('click', function () {
    deleteTagForm(tagid, type);
  });
});

$('.securityButton').on('click', function () {
  $('#securityTagModal').modal('toggle');
});



$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

$(".tagNoteView").each(function () {
  this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
}).on("input", function () {
  this.style.height = "auto";
  this.style.height = (this.scrollHeight) + "px";
});

$(".tagNoteEdit").each(function () {
  this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
}).on("input", function () {
  this.style.height = "auto";
  this.style.height = (this.scrollHeight) + "px";
});

$("#tagDownloadSVG").on('click', function() {
  var svg = document.querySelector('#tagSVG');         // find the SVG element
  svgsaver.asSvg(svg); 
})
$("#tagDownloadPNG").on('click', function() {
  var svg = document.querySelector('#tagSVG');         // find the SVG element
  svgsaver.asPng(svg); 
})


if ($('.accessEmails').length > 0) {
  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  function createUserPill(email) {
    const userPill = $('<div>', { class: 'userpill' });
    const textSpan = $('<span>').text(email);
    const closeBtn = $('<button>', { class: 'btn closebtn' });
    const closeImg = $('<img>', { src: '/images/close.svg', style: 'width: 13px; height: 13px' });

    closeBtn.append(closeImg);
    userPill.append(textSpan, closeBtn);

    closeBtn.on('click', function() {
      // Remove the user pill from the container
      userPill.remove();

      // Remove the corresponding email from the array
      const indexToRemove = emails.indexOf(email);
      if (indexToRemove !== -1) {
        emails.splice(indexToRemove, 1);
      }
    });

    return userPill;
  }

  function updateEmailsAndPills() {
    // Clear previous user pills
    emailRow.empty();

    emails.forEach(email => {
      const userPill = createUserPill(email);
      emailRow.append(userPill);
    });
  }

  // Initial update for the page load using provided emails array
  updateEmailsAndPills();

  emailInput.on('keydown', function(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      const enteredText = emailInput.val();
      if (enteredText !== '') {
        const enteredEmails = enteredText.split(',').map(email => email.trim());

        // Update the emails array with valid emails
        enteredEmails.forEach(email => {
          if (isValidEmail(email)) {
            emails.push(email);
          }
        });

        // Update user pills and display
        updateEmailsAndPills();

        emailInput.val('');
      }
    }
  });
}


  
