/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const login = async (email, password) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/login',
      data: {
        userEmail: email,
        userPassword: password,
      },
    });
    if (res.data.status === 'success') {
      window.location.href = "/existing-user-onboarding";
    }
  } catch (err) {
    showAlert('error', "An error has occured, please try again.");
  }
};

export const register = async (userName, userEmail, userPassword, userPasswordConfirm) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/signup',
      data: {
        userName,
        userEmail,
        userPassword,
        userPasswordConfirm,
      },
    });
    if (res.data.status === 'success') {
      showAlert('success', 'You have been registered!');
    } 
  } catch (err) {
    showAlert('error', 'An error has occured, please try again.');
  }
};

export const logout = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/api/v1/users/logout',
    });
    if (res.data.status === 'success') {
      window.setTimeout(() => {
        location.assign('/login');
      });
    }
  } catch (err) {
    showAlert('error', 'An error has occured, please try again.');
  }
};

export const forgotPassword = async (userEmail) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/forgotpassword',
      data: {
        userEmail: userEmail,
      },
    });
    $('.forgotForm').remove();
    $('#forgotText').text("If there is an account associated with this email address, you will receive an email with reset instructions shortly. Be sure to check your spam folder.");
    
  } catch (err) {
    showAlert('error', 'An error has occured, please try again.');
  }
};

export const resetPassword = async (userPassword, userPasswordConfirm) => {
  let url = window.location;
  let token = new URL(url);
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/resetpassword/${token.pathname.split('/')[2]}`,
      data: {
        userPassword: userPassword, 
        userPasswordConfirm: userPasswordConfirm
      },
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Your password has been updated! You will be redirected in 5 seconds.');
      window.setTimeout(() => {
        location.assign('/dashboard');
      }, 5000);
    } 
  } catch (err) {
    showAlert('error', 'An error has occured, please try again.');
  }
};