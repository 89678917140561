/* eslint-disable */
export const showAlert = (type, msg) => {
  $(".notification").css("display", "inline");
  setTimeout(() => {
    if (type === 'success') {
      $(".status").text("Success");
      $(".message-text").text(msg);
      $(".fa-solid").addClass("fa-check");
    }
    if (type === 'error') {
      $(".status").text("Error");
      $(".message-text").text(msg);
      $(".fa-solid").addClass("fa-xmark");
    }
    if (type === 'warn') {
      $(".status").text("Warning");
      $(".message-text").text(msg);
      $(".fa-solid").addClass("fa-exclamation");
    }
  $(".notification").addClass(`notification-${type}`);
  $(".notification").addClass("active");
  $(".progress").addClass("active");
    setTimeout(() => {
      $(".notification").removeClass("active");
      setTimeout(() => {
        $(".progress").removeClass("active");
        $(".status").text("");
        $(".message-text").text("");
        $(".notification").css("display", "none");
      }, 1000);
    }, 5000);
}, 500);
};

$(".close").on("click", (e) => {
  $(".notification").removeClass("active");
  setTimeout(() => {
    $(".notification").css("display", "none");
  }, 1000);
})
