/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const getCheckoutSession = async (mode, productIndex) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/orders/create-checkout-session',
      data: {
        productIndex,
				mode,
      },
    });
    if (res.data.status === 'success') {
      window.setTimeout(() => {
        location.assign(res.data.data.url);
      });
    }
  } catch (err) {
    showAlert('error', "Please correct the following errors and try again.");
  }
};

export const getPortalSession = async () => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/orders/create-customer-portal',
    });
    if (res.data.status === 'success') {
      window.setTimeout(() => {
        location.assign(res.data.data.url);
      });
    }
  } catch (err) {
    showAlert('error', "There was an error, please try again.");
  }
};